// AIPhotoEffect.js
import React, { useEffect, useRef } from 'react';
import p5 from 'p5';
import imageSrc from './image.js';

const AIPhotoEffect = () => {
    const sketchRef = useRef();

    useEffect(() => {
        new p5((p) => {
            let img;
            const horizOffset = 0; // Horizontal offset
            const vertOffset = 0;  // Vertical offset
            const tileSize = 20; // Size of each tile

            p.preload = () => {
                img = p.loadImage(imageSrc);
            };

            p.setup = () => {
                const canvasWidth = p.windowWidth;
                const canvasHeight = canvasWidth * (img.height / img.width);
                p.createCanvas(canvasWidth, canvasHeight);
                p.imageMode(p.CENTER);
                img.resize(canvasWidth, canvasHeight);
            };

            p.draw = () => {
                p.background(255); // White background

                const numCols = Math.ceil(p.width / tileSize);
                const numRows = Math.ceil(p.height / tileSize);

                for (let col = 0; col < numCols; col++) {
                    for (let row = 0; row < numRows; row++) {
                        const x = col * tileSize;
                        const y = row * tileSize;
                        const tileCenterX = x + tileSize / 2;
                        const tileCenterY = y + tileSize / 2;
                        // Calculate distance from mouse to tile center
                        const distance = p.dist(p.mouseX, p.mouseY, tileCenterX, tileCenterY);

                        // Calculate an offset for each tile to create a wavy effect
                        const noiseOffset = p.noise(col * 0.01, row * 0.01, p.frameCount * 0.01) * 20;
                        let offsetX = noiseOffset + horizOffset;
                        let offsetY = noiseOffset + vertOffset;

                        // Disperse tiles based on mouse distance
                        const maxDisplacement = 50; // Max distance tiles will move when hovered
                        const disperseFactor = p.map(distance, 0, 150, maxDisplacement, 0, true);

                        offsetX += (tileCenterX - p.mouseX) / distance * disperseFactor;
                        offsetY += (tileCenterY - p.mouseY) / distance * disperseFactor;

                        const imgX = col * tileSize;
                        const imgY = row * tileSize;

                        p.image(img, x + offsetX, y + offsetY, tileSize, tileSize, imgX, imgY, tileSize, tileSize);
                    }
                }
            };

            p.windowResized = () => {
                const canvasWidth = p.windowWidth;
                const canvasHeight = canvasWidth * (img.height / img.width);
                p.resizeCanvas(canvasWidth, canvasHeight);
                img.resize(canvasWidth, canvasHeight);
            };
        }, sketchRef.current);

        return () => sketchRef.current.innerHTML = ''; // Cleanup on component unmount
    }, []);

    return <div ref={sketchRef} style={{ width: '100%', height: 'auto' }}></div>;
};

export default AIPhotoEffect;