import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Contact from "./routes/contact";
import {NavLink} from "react-router-dom";
import {Link} from "react-router-dom";
import  { useState,Suspense } from "react";
import{
    createBrowserRouter,
    RouterProvider,
    Route
} from "react-router-dom";
import App from "./App";
import React from "react";
import './App.css';

const router1 = createBrowserRouter(
    [{
        path: "contact/",
        element: <Contact />,
    },
    ]);

function Navboot(props) {

    return (
        <Nav fill="true"  variant="pills" defaultActiveKey="/home" className="flex-column">
            <Nav.Link bsPrefix={"Navv"} onClick={() => props.onclick}>Parham</Nav.Link>
            <Nav.Link bsPrefix={"Navv"}  eventKey="link-1" >Link</Nav.Link>
            <Nav.Link bsPrefix={"Navv"} eventKey="link-2">Link</Nav.Link>

        </Nav>
    );
}
export default Navboot;