import logo from './logo.svg';
import './App.css';
import Navboot from "./NavBoot";
import {Routes, Route, Link} from "react-router-dom";
import Backg from "./components/backg";
import React from "react";
import Contact from "./routes/contact";
import {Parallax, ParallaxLayer} from '@react-spring/parallax';
import Typewriter from 'typewriter-effect';
import {useState, Suspense} from "react";

import leaf from './black-leaf.jpg';
import color from './colourful.jpg';
import Nav from "react-bootstrap/Nav";
import Skills1 from "./components/Skills1";
import Employment from "./components/Employment";
import Title from "./components/Title";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';



import checklist from "./icons/checklist.gif";
import hacker from "./icons/hacker.gif";
import message from "./icons/message.gif";
import mortarboard from "./icons/mortarboard.gif";
import diploma from "./icons/diploma.gif";
import certificate from "./icons/certificate.gif";
import ParticleImage from "./components/ParticleImage";
import AIPhotoEffect from "./components/AIPhotoEffect";
import {TabPane} from "react-bootstrap";
import Publications from "./components/Publications";
import Publications_List from "./components/Publications_List";
import CitationsChart from "./components/CitationsChart";


const Name = '<p style ="font-size:calc(32px + 40 * ((100vw - 320px) / 680)) ; color:#000000 ; font-weight: bold " >Parham Mohammadi</p>';
const Message = '<p style ="font-size:calc(10px + 20 * ((100vw - 320px) / 680));color:yellow " >Welcome to my website</p>';
const Message2 = '<p style ="font-size:calc(10px + 20 * ((100vw - 320px) / 680));color:yellow  ; font-weight:bold" >Blockchain Developer</p>';
const Message3 = '<p style ="font-size:calc(10px + 20 * ((100vw - 320px) / 680));color:yellow  ; font-weight:bold" >DevOps Engineer</p>';
const Message4 = '<p style ="font-size:calc(10px + 20 * ((100vw - 320px) / 680));color:yellow  ; font-weight:bold" >Machine Learning Engineer</p>';

function App() {
  /* let element: IParallax | null

   const [buttonMessage, setButtonMessage] = useState('one')
*/
  /* const handlescroll = () => {
       if (buttonMessage === 'one') {
           setButtonMessage('two')
           element?.scrollTo(1.5)
       }
       if (buttonMessage === 'two') {
           setButtonMessage('three')
           element?.scrollTo(1)
       }
       if (buttonMessage === 'three') {
           setButtonMessage('one')
           element?.scrollTo(0)
       }
   }*/
  /*

      const handlescroll = () => {
          element?.scrollTo(0)
      }
      const handlescroll1 = () => {
          element?.scrollTo(0.999)
      }
      const handlescroll2 = () => {
          element?.scrollTo(2.2)
         }
      const handlescroll3 = () => {
          element?.scrollTo(2.999)
      }
      const handlescroll4 = () => {
          element?.scrollTo(4.3)
      }
      const handlescroll5 = () => {
          element?.scrollTo(5)
      }
      const handlescroll6 = () => {
          element?.scrollTo(6)
      }
  */


  return (
      <div className="App">
        <header className="App-header">
          <Parallax pages={4} /*ref={ref => element = ref}*/ >
            {/*  <ParallaxLayer sticky={{start:0,end:9}} style={{  background : "rgba(0,0,0,0.76)" , top:"0" ,width : "10vw" , height:"100%" }}  >
                <Nav fill="true"  variant="pills" defaultActiveKey="/home" className="flex-column">
                    <Nav.Link bsPrefix={"Navv"} onClick={() => handlescroll()}>Parham</Nav.Link>
                    <Nav.Link bsPrefix={"Navv"} eventKey="link-1" onClick={() => handlescroll1()}>Experience</Nav.Link>
                    <Nav.Link bsPrefix={"Navv"} eventKey="link-2" onClick={() => handlescroll2()}>Education</Nav.Link>
                    <Nav.Link bsPrefix={"Navv"} eventKey="link-2" onClick={() => handlescroll3()}>Skills</Nav.Link>
                    <Nav.Link bsPrefix={"Navv"} eventKey="link-2" onClick={() => handlescroll4()}>Certificates</Nav.Link>
                    <Nav.Link bsPrefix={"Navv"} eventKey="link-2" onClick={() => handlescroll5()}>Honors</Nav.Link>
                    <Nav.Link bsPrefix={"Navv"} eventKey="link-2" onClick={() => handlescroll6()}>Contact</Nav.Link>
                </Nav>
            </ParallaxLayer>*/}
            {/*    <ParallaxLayer speed={0} factor={1}>
                <div className="Name-D">
                    <Typewriter
                        onInit={(typewriter  ) => {
                            typewriter.typeString(Name)
                                .callFunction(() => {
                                    console.log('String typed out!');
                                })
                                .pauseFor(2500)
                                .callFunction(() => {
                                    console.log('All strings were deleted');
                                })
                                .start();
                        }}
                    />
                    <Typewriter

                        onInit={(typewriter  ) => {
                            typewriter.typeString(Message)
                                .callFunction(() => {
                                    console.log('String typed out!');
                                })
                                .pauseFor(3500).deleteAll()
                                .callFunction(() => {
                                    console.log('All strings were deleted');
                                })
                                .start();
                        }}
                    />

                </div>
             </ParallaxLayer>
            <ParallaxLayer offset={1} speed={0} factor={0.5} style={{  backgroundSize:'cover' , zIndex : 100 }}>
            <div className="IAM">
                <p className="IAMtext">I'm a  </p>
                <Typewriter
                    options={{
                        strings: [Message2, Message3, Message4],
                        autoStart: true,
                        loop: true,
                    }}
                />
           </div>
                </ParallaxLayer>*/}
            <ParallaxLayer offset={0} speed={0} factor={0.5} style={{backgroundSize: 'cover', zIndex: 100}}>
              {/*<ParticleImage/>*/}
              <AIPhotoEffect/>
            </ParallaxLayer>
            <ParallaxLayer offset={0} speed={0} factor={3} style={{
              backgroundColor: "rgba(255,255,255,0)",
              backgroundSize: 'cover',
              zIndex: 100
            }}>
              <Tabs>
                <TabList className="my-tab-list">
                  {/*<Tab className="react-tabs__tab">About Me</Tab>*/}
                  {/*<Tab className="react-tabs__tab">Experience</Tab>*/}
                  {/*<Tab className="react-tabs__tab">Education</Tab>*/}
                  {/*<Tab className="react-tabs__tab">Skills</Tab>*/}
                  {/*<Tab className="react-tabs__tab">Certificates</Tab>*/}
                  {/*<Tab className="react-tabs__tab">Honors</Tab>*/}
                  {/*<Tab className="react-tabs__tab">Contact</Tab>*/}
                  {/*<Tab className="react-tabs__tab">Publications</Tab>*/}
                </TabList>
                <TabPanel>

                </TabPanel>


              </Tabs>
            </ParallaxLayer>
          </Parallax>
        </header>
      </div>
  )
}

export default App;
